import * as React from 'react';
import styled from 'styled-components';

const ServiceTrigger = () => (
  <ServiceTriggerWrapper>
    <p>
      U kan bij mij terecht voor zaken omtrent bouwrecht. Klik op deze tekst om de diensten te tonen en later terug te verbergen,
      of gebruik de knop hieronder.
    </p>
    <p className="openServiceText">BEKIJK ALLE DIENSTEN</p>
  </ServiceTriggerWrapper>
);

export default ServiceTrigger;

const ServiceTriggerWrapper = styled.div`
  margin: auto;
  max-width: 800px;
  opacity: 1;
  padding: 20px 0;
  text-align: center;
  transition: all 0.3s ease-in-out;

  .openServiceText {
    color: #d26426;
    font-weight: 700;
    padding-top: 20px;
    letter-spacing: 0px;
    transition: all 0.3s ease-in-out;
  }
`;
