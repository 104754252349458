import { Box, Flex } from '@rebass/grid';
import React from 'react';
import Collapsible from 'react-collapsible';
import styled from 'styled-components';
import Servicess from '../../assets/images/services.svg';
import ServicesList from './ServicesList';

const Services = () => (
  <Flex justifyContent="center">
    <ServicesWrapper>
      <Flex p={3} justifyContent="center" flexWrap="wrap" alignItems="center">
        <ImageWrapper p={3}>
          <ServicesImg src={Servicess} alt="services" />
        </ImageWrapper>
        <Box p={3}>
          <Styledh1>Diensten</Styledh1>
          <p>Wat ik voor u kan betekenen.</p>
        </Box>
      </Flex>

      <ServicesList />

      <Box p={3}>
        <Styledh2>Het kantoor Reynaert advocaten gaat als volgt te werk:</Styledh2>
      </Box>

      <Flex alignItems="center" justifyContent="center" flexWrap="wrap" p={3}>
        <Step className="right" width={[1, 5 / 6, 7 / 8]}>
          <Header>
            <h3>1. Eerste kennismaking</h3>
          </Header>
          <Content>
            <Collapsible trigger="Een eerste kennismaking, bespreking, aftoetsing van de problematiek is gratis en tevens richtinggevend in de verdere samenwerking met de mogelijke cliënt of betrokkene.">
              <Body>
                De bedoeling van het eerste gesprek en verkenning is, om zowel voor de cliënt, als het kantoor de problematiek te
                schetsen, en prima facie de gevoelsmatigheden en intellectuele benadering van de zaak aan te voelen, en dit om na
                te gaan of tussen beide partijen een constructieve consensus kan worden gevonden in de benadering van de
                voorliggende bezorgdheden. De dienstverlening heeft als uiteindelijk doel om op de meest diligente en opbouwende
                wijze het gestelde probleem te verhelpen, en alle nodige maatregelen te nemen die tot de meest snelle en kost
                efficiënte manier de voldoening van de cliënt èn zijn raadsman kunnen nastreven.
              </Body>
            </Collapsible>
          </Content>
        </Step>
      </Flex>
      <Flex alignItems="center" justifyContent="center" flexWrap="wrap-reverse" p={3}>
        <Step className="left" width={[1, 5 / 6, 7 / 8]}>
          <Header>
            <h3>2. Bespreking voorliggende bezorgdheid</h3>
          </Header>
          <Content>
            <Collapsible trigger="Eerst luisteren en inschatten wat de problematiek is die cliënt heeft aangezet om de stap te zetten naar het kantoor. Een mens heeft twee oren en één mond om twee keer zoveel te luisteren dan te praten. (Confucius)">
              <Body>
                Het moet duidelijk zijn dat het daarbij hoegenaamd niet de bedoeling is om, via gerechtelijke weg, dat doel te
                bereiken. Het is de filosofie van het kantoor om zo efficiënt mogelijk het maximaal haalbare resultaat te behalen
                op een definitieve wijze met prioritair de constructieve dialoog. Deze filosofie is ingegeven door de jarenlange
                ervaring waarbij het beroep als advocaat in zijn juridische dienstverlening geregeld onder vuur kwam te liggen,
                waarbij ootmoedig ook het eigen beroep en zijn deelnemers mede aan de basis van deze perceptie liggen, mede
                veroorzaakt door de hen terecht opgelegde discretie en terughoudendheid.
              </Body>
            </Collapsible>
          </Content>
        </Step>
      </Flex>
      <Flex alignItems="center" justifyContent="center" flexWrap="wrap" p={3}>
        <Step className="right" width={[1, 5 / 6, 7 / 8]}>
          <Header>
            <h3>3. Opties behandeling problemen</h3>
          </Header>
          <Content>
            <Collapsible
              trigger=" De voorlegging en evaluatie van de diverse opties om het probleem te benaderen, weze het de dialoog, confrontatie
                of gerechtelijke weg, dat alles met pro’s en contra’s en absolute vrijwaring van de rechten van de cliënt en alle
                te nemen bewarende maatregelen. Het in stelling brengen van alle beschikbare middelen."
            >
              <Body>
                <p>
                  Het is dan ook de ultieme drijfveer van het kantoor om een zaak dermate te benaderen dat in zeer uitgebreid
                  overleg, en in continue en gedetailleerde communicatie met de cliënt, de opvolging van het dossier wordt
                  benaarstigd, zodanig dat de cliënt de opbouw en de richting van het verloop van zijn eigen zaak kan volgen, en
                  waarbij van de cliënt ook wordt gevraagd om actief bij het kantoor tussen te komen daar waar hij enige
                  meerwaarde en constructieve inbreng, in samenwerking met het kantoor, kan worden bewerkstelligd.
                </p>
                <p>
                  De dienstverlening start bij kennismaking en advies, verder over de dialoog met alle andere betrokken partijen
                  in het contentieux, of de beheersing van de contractuele relaties, tot het, indien absoluut nodig, begeleiden en
                  integraal behandelen van de noodzakelijke gerechtelijke expertises en tussenkomsten en pleidooien voor de
                  bevoegde rechtbank.
                </p>
              </Body>
            </Collapsible>
          </Content>
        </Step>
      </Flex>
      <Flex alignItems="center" justifyContent="center" flexWrap="wrap-reverse" p={3}>
        <Step className="left" width={[1, 5 / 6, 7 / 8]}>
          <Header>
            <h3>4. Bespreking en voorwaarden diensten overeenkomst advocaat – cliënt</h3>
          </Header>
          <Content>
            <Collapsible
              trigger="Eens de strategie en tactiek besproken en bepaald, is het tijd om de relatie advocaat – cliënt in alle openheid te
                bespreken en tot duidelijke consensus te groeien, met de dienstenovereenkomst tussen beide als essentieel
                onderdeel van een open-minded relatie."
            >
              <Body>
                <p>
                  Een zeer belangrijke leidraad is het absolute vertrouwen dat dient te heersen tussen het kantoor en de cliënt
                  waarbij, voor zover als nodig en relevant, de ingesteldheid van het kantoor dermate is, dat de cliënt, indien de
                  noodzaak binnen het kantoor wordt gevoeld, ook onmiddellijk ten dele of geheel, gestuurd wordt naar samenwerking
                  van het kantoor met juridische specialisten die de expertise en het vertrouwen van het kantoor verdiend hebben.
                  Deze worden enkel betrokken in het advies of contentieux, na duidelijke afspraken met de cliënt.
                </p>
                <p>
                  Indien de cliënt zich op de ene of andere wijze niet vertrouwd, onbehagelijk of niet begrijpend voelt in het
                  verloop van zijn zaak dan dient hij, vanaf de eerste tot de laatste minuut van de tussenkomst, te weten dat de
                  deur altijd open staat voor een “open-minded” rechtstreeks confronterend onderhoud.
                </p>
                <p>
                  Daarbij is het kantoor maximaal bereikbaar, waarbij het de bedoeling is om alle verbale of andere communicatie
                  binnen de kortste keren te beantwoorden, en tevens elke communicatie die vanuit het kantoor vertrekt ter
                  informatie aan de cliënt mede te delen.
                </p>
                <p>
                  Dit per evidentie met het respect voor alle deontologische regels met betrekking tot de vertrouwelijke
                  communicatie tussen advocaten.
                </p>
                <p>
                  Dit laatste uiteindelijk een voordeel zijnde, om in een geschil de mogelijkheden tot het bereiken van een
                  snelle, constructieve en efficiënte oplossing te bereiken.
                </p>
              </Body>
            </Collapsible>
          </Content>
        </Step>
      </Flex>
      <Flex alignItems="center" justifyContent="center" flexWrap="wrap" p={3}>
        <Step className="right" width={[1, 5 / 6, 7 / 8]}>
          <Header>
            <h3>5. Minnelijke pogingen – bemiddeling</h3>
          </Header>
          <Content>
            <Collapsible
              trigger="Alles in stelling gebracht zijnde en mits consensus tussen advocaat en cliënt wordt gestart met een
minnelijke poging tot ontmijning van het geschil, bemiddeling en of zeer uitdrukkelijke in gebreke stelling
aan tegenpartij."
            >
              <Body>
                <p>
                  Voor het geval tegenpartij onwillig of ongevoelig is voor het constructief oplossen van het probleem, of de
                  verwerpelijke houding “uit principe” hanteert, zal uiteindelijk slechts de gerechtelijke optie resten. Doch de
                  ervaring leert dat minstens voldoende aandacht moet besteed worden aan de eerste fase van het onderzoek naar een
                  minnelijke oplossing.
                </p>
                <p>
                  Dat is uitermate belangrijk omdat op die wijze meestal op de snelste wijze naar een definitieve oplossing van
                  het geschil kan worden gestreefd. Al zijn de wederzijdse rechtmatige toegevingen aan beide zijden en de daarmee
                  gepaard gaande confrontatie bitter om slikken, toch leveren zij soms naar de toekomst toe de fundamenten voor
                  een hersteld vertrouwen. Al blijven de littekens en schrammen, de eerdere goede relatie is niet noodzakelijk
                  voor altijd verloren.
                </p>
              </Body>
            </Collapsible>
          </Content>
        </Step>
      </Flex>
      <Flex alignItems="center" justifyContent="center" flexWrap="wrap-reverse" p={3}>
        <Step className="left" width={[1, 5 / 6, 7 / 8]}>
          <Header>
            <h3>6. Dringend en noodzakelijke maatregelen – opschorting gerechtelijke vervaltermijnen</h3>
          </Header>
          <Content>
            <Collapsible
              trigger="Het is echter wel absoluut noodzakelijk om nooit de dringende maatregelen en de gerechtelijke
vervaltermijnen in het oog te houden. Dat behoort immers tot de adviserende rol en de uiteindelijke
beroepsverantwoordelijkheid van uw advocaat."
            >
              <Body>
                <p>
                  Het nemen van de noodzakelijke gerechtelijke maatregelen sluit echter niet uit dat de vorige fase moet worden
                  verlaten.
                </p>
                <p>
                  Ten einde de vorige fase niet te ondermijnen, kan men immers, doch dat is niet noodzakelijk, in de geding
                  inleidende akte uitdrukkelijk motiveren waarom men al “ten bewarende titel” de gerechtelijke fase opstart. Het
                  is niet omdat zij terzelfdertijd geopend wordt, en ze op dat platform tijdelijk onbepaald wordt uitgesteld, dat
                  niet kan teruggevallen worden op de bemiddelingsfase. Integendeel ze kan precies die minnelijke fase versterken
                  omdat de tegenpartij dan precies weet dat indien de constructie onderhandeling niet wordt afgewerkt,
                  onvermijdelijk de (her)opstart van de gerechtelijke fase het onvermijdbare en evidente gevolg is van haar
                  onwillige houding.
                </p>
              </Body>
            </Collapsible>
          </Content>
        </Step>
      </Flex>
      <Flex alignItems="center" justifyContent="center" flexWrap="wrap" p={3}>
        <Step className="right" width={[1, 5 / 6, 7 / 8]}>
          <Header>
            <h3>7. Contentieux in gerechtelijke fase</h3>
          </Header>
          <Content>
            <Collapsible
              trigger="Wanneer alle minnelijke pogingen mislukken en tegenpartij niet wil luisteren naar de wijze en rijpe
voorstellen, kan en moet de gerechtelijke scène het verhoopte en weloverwogen resultaat opleveren."
            >
              <Body>
                <p>
                  Dit gerechtelijke bedrijf in het totale toneel, is soms niet te vermijden, en een elementair instrumentum om het
                  contentieux de wereld uit helpen. Precies daarvoor is het historische instituut ter bescherming van het recht
                  gecreëerd.
                </p>
                <p>
                  Indien partijen er onderling niet in slagen, dan is de tussenkomst van “Vrouwe Justitia “ de eventueel zalvende
                  wijze moeder die op juridische en gemotiveerde gronden partijen op die plaats neerpoot waar ze conform
                  wetgeving, rechtspraak en rechtsleer dienen plaats te nemen.
                </p>
                <p>
                  Het is de taak van de advocaat om zijn cliënt op dat vlak te verdedigen en alle ter zijner beschikking zijnde
                  wettelijke middelen aan te wenden om het in de eerste fase van de kennismaking besproken doel te bereiken.
                </p>
              </Body>
            </Collapsible>
          </Content>
        </Step>
      </Flex>
    </ServicesWrapper>
  </Flex>
);

export default Services;

const ServicesWrapper = styled(Box)`
  max-width: 1128px;
  border-bottom: 1px solid lightgrey;
  padding-bottom: 50px;
`;

const Styledh2 = styled.h2`
  border-left: 3px solid #d26426;
  padding-left: 5px;
  margin-bottom: 20px;
`;

const Step = styled(Box)`
  background-color: white;
  z-index: 1;
  box-shadow: 0 4px 6px 0 rgba(182, 182, 182, 0.2);

  .Collapsible {
    margin-bottom: 15px;
    text-align: left;
    position: relative;

    .is-closed {
      :after {
        content: '+ lees meer';
        color: #d26426;
        font-weight: 400;
        position: absolute;
        right: 0;
        bottom: 0;
        margin-left: 10px;
      }
    }
    .is-open {
      :after {
        content: '- sluiten';
        color: #d26426;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }

  .Collapsible__trigger {
    display: block;
    cursor: pointer;
    padding-bottom: 40px;
  }
`;

const Header = styled.div`
  padding: 5px 10px;
  border-bottom: 1px solid #d26426;
  // background-color: #d26426;
  color: black;
`;

const Content = styled.div`
  padding: 10px;
  background-color: white;
`;

const Body = styled.div`
  padding: 20px 0;
`;

const Styledh1 = styled.h1`
  color: #d26426;
`;

const ServicesImg = styled.img`
  max-height: 300px;
  max-width: 90%;
`;

const ImageWrapper = styled(Box)`
  text-align: center;
  @media (max-width: 639px) {
    display: none;
  }
`;
