import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Flex } from '@rebass/grid';
import React, { Component } from 'react';
import Collapsible from 'react-collapsible';
import styled from 'styled-components';
import ServiceTrigger from './ServiceTrigger';

class ServicesList extends Component {
  constructor() {
    super();
    this.state = {
      data: [
        'contracten bouwrecht (aanneming van werk, architectuur, technische studies, etc...)',
        'uitvoering beroep van aannemer / architect / studiebureau / veiligheidscoördinator',
        'opdracht bouwpartners (concept en controle ; keuze materialen ; informatieplicht)',
        'beperkingen verantwoordelijkheid en tegenstelbaarheid',
        'relaties tussen diverse bouwpartners onderling en verhouding tot bouwheer',
        'aansprakelijkheid en rechtsvordering tegen onder-aannemers',
        'deskundigenonderzoek en gerechtelijke expertise',
        'aansprakelijkheid bouwpromotor - resultaatsverbintenis',
        'pre-contractuele aansprakelijkheid',
        'algemeen verbintenissenrecht',
        'bewijsrecht',
        'overlijden / insolventie van contractspartijen',
        'auteursrechten',
        'invordering facturen en erelonen (B2B invordering facturen)',
        'bouwbudget overschrijdingen (meerwerken - minwerken)',
        'aanneming tegen vaste prijs en werken in regie',
        'architectenvennootschappen',
        'aansprakelijkheid verborgen gebreken versus 10-jarige aansprakelijkheid',
        'voorlopige en definitieve oplevering',
        "verplichte verzekeringen architect - aannemer - studiebureau's",
        'opvolging van architecten - aannemers tijdens uitvoering opdrachten',
        'erkenning van aannemers van werken (Wet 20 maart 1991)',
        'overheidsopdrachten (Wet 17 juni 2016)',
        'aansprakelijkheid 544 B.W. (foutloos) en art. 1382 BW. (foutaansprakelijkheid)',
        "verzekering in de bouw (aansprakelijkeheidsverzekering - Algemene bouwplaatsrisico's (verzekeringsnemer verzekerde- Afdelingen I & II)",
        'ontbinding overeenkomst (werking ex tunc - ex nunc)',
        'goederenrecht (zakenrecht)',
        'eigendom - vruchtgebruik - persoonlijke rechten',
        'erfdienstbaarheden',
        'recht van opstal',
        'Wet Breyne',
        'mede-eigendom - appartementsrecht',
      ],
      input: '',
    };
  }
  onChangeHandler(e) {
    this.setState({
      input: e.target.value,
    });
  }
  render() {
    const list = this.state.data
      .filter((d) => this.state.input === '' || d.includes(this.state.input.toLowerCase()))
      .map((d, index) => <ServiceListItem key={index}>{d}</ServiceListItem>);

    return (
      <ServicesWrapper justifyContent="center" p={3} flexWrap="wrap">
        <ServiceCollapsable>
          <Collapsible trigger={<ServiceTrigger />}>
            <SearchWrapper>
              <SearchBar
                type="text"
                placeholder="Typ hier om de lijst te filteren"
                value={this.state.input}
                onChange={this.onChangeHandler.bind(this)}
              />
              <SearchIcon icon={faSearch} />{' '}
            </SearchWrapper>
            <Flex flexWrap="wrap">
              {(list.length > 0 && <ServiceList>{list}</ServiceList>) || <p>Geen resultaten gevonden.</p>}
            </Flex>
          </Collapsible>
        </ServiceCollapsable>
      </ServicesWrapper>
    );
  }
}

export default ServicesList;

const ServiceList = styled.div`
  margin: 0;
`;

const ServiceListItem = styled.p`
  border-radius: 4px;
  padding: 8px;
  :nth-child(even) {
    background: #fff7f3;
  }
`;

const ServicesWrapper = styled(Flex)`
  border-bottom: 1px solid lightgrey;
  padding-bottom: 70px;
  margin-bottom: 30px;
`;

const ServiceCollapsable = styled(Box)`
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 6px 0 rgba(182, 182, 182, 0.2);
  max-width: 1128px;
  width: 100%;
  padding: 10px 10px;
  transition: box-shadow 0.3s ease-in-out;
  &:hover {
    box-shadow: 0 4px 14px 0 rgba(182, 182, 182, 0.4);
    transition: box-shadow 0.3s ease-in-out;
    .openServiceText {
      letter-spacing: 1px;
      transition: all 0.3s ease-in-out;
    }
  }
  .Collapsible {
    position: relative;
    text-align: left;
    .is-open {
      .openServiceText {
        opacity: 0;
        transition: all 0.3s ease-in-out;
        visibility: hidden;
      }
    }
  }

  .Collapsible__trigger {
    cursor: pointer;
    display: block;
  }
`;

const SearchWrapper = styled.div`
  position: relative;
`;

const SearchBar = styled.input`
  border-radius: 20px;
  border: 1px solid lightgrey;
  margin-bottom: 30px;
  padding: 10px 14px;
  text-decoration: none;
  width: 100%;
`;

const SearchIcon = styled(FontAwesomeIcon)`
  color: lightgrey;
  position: absolute;
  right: 12px;
  top: 12px;
`;
