import React from 'react';
import GlobalHead from '../config/GlobalHead';
import GlobalStyle from '../config/GlobalStyle';
import { Provider } from '../contexts/context';
import Footer from '../modules/footer/Footer';
import Header from '../modules/navigation/Header';
import Services from '../modules/services';

const Diensten = () => (
  <Provider>
    <GlobalStyle />
    <GlobalHead />
    <Header />
    <Services />
    <Footer />
  </Provider>
);

export default Diensten;
